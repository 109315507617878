<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Invoices",
  data: function () {
    return {
      fields: [
        { key: 'date', label: 'Datum' },
        { key: 'number', label: 'RE Nummer' },
        {
          key: 'gross_amount',
          label: 'Summe',
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: value => {
            return parseFloat(value).toFixed(2).replace('.', ',') + ' €'
          },
        },
      ],
      meta: {
        per_page: 10
      },
      invoices: []
    }
  },
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  methods: {
    loadInvoices(ctx, callback) {
      let self = this;
      const params = '?page=' + ctx.currentPage + '&per_page=' + ctx.perPage;
      Http.request('GET', '/user/bills' + params).then(function (invoices) {
        self.meta = invoices.meta;
        self.invoices = invoices.data;
        callback(invoices.data)
      });
      return null
    },
    getInvoice(invoice){
      Http.requestFile('/user/bills/' + invoice.id, invoice.number, true);
    }
  }
}
</script>

<style scoped></style>
